import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import SEO from "./SEO"
import Footer from "./Footer"
import Navbar from "./Navbar"
import Step from "./Steps/Step"
import Steps from "./Steps/index"
import Container from "./Container"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true, bottomMargin: true },
  path,
  pageContext,
  steps = [],
  location,
}) => {
  let pathArray = path ? path.split("/") : []

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <Navbar path={pathArray} />
      <Notification pageContext={pageContext} />

      {title && (
        <Container isCentered>
          <h2
            className={classNames(
              "has-text-centered mb-0 has-text-primary mx-2-mobile is-size-3-mobile",
              {
                "pb-0": !subtitle,
                "pb-2": !!subtitle,
              }
            )}
          >
            {title}
            <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-4 mx-1-mobile is-size-5-mobile">
              {subtitle}
            </h4>
          </h2>
          {steps?.length > 0 ? (
            <div className="mb-1">
              <Steps>
                {steps?.map((step, index) => (
                  <Step
                    step={step}
                    location={location}
                    steps={steps}
                    index={index}
                  />
                ))}
              </Steps>
            </div>
          ) : null}
        </Container>
      )}
      <main className={classNames({ "mb-3": display?.bottomMargin })}>
        {children}
      </main>
      {display.helpCenterBanner && (
        <HelpCenterBanner module={pageContext?.module?.name} />
      )}
      {display.footer && <Footer />}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
