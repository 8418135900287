import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { navigate } from "gatsby"
import React from "react"

const Step = ({ steps, step, location, index }) => {
  let isActive = step?.pathname === location?.pathname
  let currentActiveStepIndex = steps?.findIndex(
    (currentStep) => currentStep?.pathname === location?.pathname
  )
  let hasFinishedStep = index <= currentActiveStepIndex

  let handleClickStep = () => {
    if (!!hasFinishedStep) navigate(step?.pathname)
    return
  }

  return (
    <li
      className={classNames("steps-segment", {
        "is-active": isActive,
        "is-clickable": !!hasFinishedStep,
      })}
      onClick={handleClickStep}
      onKeyDown={(event) => {
        if (event.key === "Enter") handleClickStep()
      }}
      //eslint-disable-next-line
      role="button"
      tabIndex={0}
    >
      <p className="steps-marker">
        <FontAwesomeIcon icon={step?.icon} className="has-text-white" />
      </p>
      <div className="steps-content">
        <p className="has-text-primary is-size-6 has-text-weight-bold">
          {step?.label}
        </p>
      </div>
    </li>
  )
}

export default Step
