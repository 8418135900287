import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Hero from "layout/Hero"
import useHomeImages from "../StaticPages/Home/hooks/useHomeImages"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const data = useHomeImages()
  const lifologicLogo = data.lifologicLogo.childImageSharp.fluid

  return (
    <Hero className="has-text-centered" color="primary">
      <figure style={{ width: "250px", margin: "0 auto" }}>
        <Img fluid={lifologicLogo} alt="Lifologic" className="is-64-by-64" />
      </figure>{" "}
      <br />
      <div className="content is-size-5-desktop is-size-6-mobile has-text-light">
        <p>
          Unit 408A, ITC Building, Sen. Gil Puyat Ave. <br />
          Makati City, Philippines
        </p>
        <p>©{currentYear}, Lifologic</p>
      </div>
    </Hero>
  )
}

export default Footer
