import { useStaticQuery, graphql } from "gatsby"

const useHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      banner: file(relativePath: { eq: "capsules.jpg" }) {
        childImageSharp {
          fluid(toFormat: PNG, quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lifologicLogo: file(
        relativePath: { eq: "logos/lifologic-logo-long.png" }
      ) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#ffffff" }
            toFormat: PNG
            quality: 100
            maxWidth: 200
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      medgrocerLogo: file(relativePath: { eq: "logos/medgrocer-logo.png" }) {
        childImageSharp {
          fixed(toFormat: PNG, quality: 100, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      medgrocerWebsite: file(relativePath: { eq: "medgrocer-website.png" }) {
        childImageSharp {
          fluid(toFormat: PNG, quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}

export default useHomeImages
