import classNames from "classnames"
import React from "react"

const Steps = ({ size, children }) => {
  return (
    <ul
      className={classNames(
        `steps has-content-centered is-${size || "medium"}`
      )}
    >
      {children}
    </ul>
  )
}

export default Steps
